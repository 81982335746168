import Routes from '@/constants/Routes';

import {
    AlignJustify,
    BadgeDollarSign,
    Banknote,
    Bell,
    Bot,
    Crosshair,
    DatabaseBackup,
    Folder,
    History,
    LayoutDashboard,
    LucideIcon,
    Radio,
    Search,
    SendToBack,
    Store,
    UserRound,
} from 'lucide-react';

export type TNavigationMenu = {
    id: string;
    type: 'link' | 'group';
    label?: string;
    variant?: 'default' | 'ghost';
    path?: string;
    icon?: LucideIcon;
    disabled?: boolean;
    children?: TNavigationMenu[];
};

const navigationMenu: TNavigationMenu[] = [
    {
        id: 'dashboard',
        type: 'link',
        label: 'Overview',
        path: Routes.DASHBOARD,
        icon: LayoutDashboard,
        variant: 'ghost',
    },
    {
        id: 'my_library',
        type: 'group',
        label: 'My library',
        icon: Folder,
        variant: 'ghost',
        children: [
            {
                id: 'profile',
                type: 'link',
                label: 'Profile',
                path: Routes.PROFILE,
                icon: UserRound,
                variant: 'ghost',
            },
            {
                id: 'exchanges',
                type: 'link',
                label: 'Exchanges',
                path: Routes.EXCHANGE,
                icon: BadgeDollarSign,
                variant: 'ghost',
            },
            {
                id: 'notification',
                type: 'link',
                label: 'Notification',
                path: Routes.NOTIFICATION,
                icon: Bell,
                variant: 'ghost',
                disabled: true,
            },
            {
                id: 'strategies',
                type: 'link',
                label: 'Strategies',
                path: Routes.STRATEGIES,
                icon: Crosshair,
                variant: 'ghost',
            },
            {
                id: 'marketplace',
                type: 'link',
                label: 'Marketplace',
                path: Routes.MARKETPLACE,
                icon: Store,
                variant: 'ghost',
            },
        ],
    },
    {
        id: 'backtest',
        type: 'group',
        label: 'Backtest',
        icon: Bot,
        variant: 'ghost',
        children: [
            {
                id: 'backtest_bots',
                type: 'link',
                label: 'Bots',
                path: Routes.BACKTEST_BOTS,
                icon: Search,
                variant: 'ghost',
            },
            {
                id: 'bots_list',
                type: 'link',
                label: 'Results',
                path: Routes.BOTS_LIST,
                icon: AlignJustify,
                variant: 'ghost',
            },
            {
                id: 'bot_history',
                type: 'link',
                label: 'History',
                path: Routes.BOT_HISTORY,
                icon: DatabaseBackup,
                variant: 'ghost',
            },
        ],
    },
    {
        id: 'live',
        type: 'group',
        label: 'Live',
        icon: Radio,
        variant: 'ghost',
        children: [
            {
                id: 'trade_bots',
                type: 'link',
                label: 'Trade Bots',
                path: Routes.TRADE_BOTS,
                icon: Banknote,
                variant: 'ghost',
            },
            {
                id: 'open_orders',
                type: 'link',
                label: 'Open Orders',
                path: Routes.OPEN_ORDERS,
                icon: SendToBack,
                variant: 'ghost',
            },
            {
                id: 'trade_history',
                type: 'link',
                label: 'Trade History',
                path: Routes.TRADE_HISTORY,
                icon: History,
                variant: 'ghost',
            },
        ],
    },
];

export default navigationMenu;
